import React from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import GetMicroCopy from "../shared/getMicroCopy"
import { useMicroCopyFi } from "../hooks/microCopy"
import { useMicroCopySv } from "../hooks/microCopy/sv"
import { useMicroCopyEn } from "../hooks/microCopy/en"
import { BgImage } from "gbimage-bridge"
import LayoutController from "../components/layoutController"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Seo from "../components/seo"
import BasicPageSidebar from "../components/basicPageSidebar"

import LiftContent from "../components/liftContent"

import * as styles from "./basicPage.module.scss"

const BasicPage = ({ data }) => {
  const pageContent = R.path(["contentfulContent"], data)
  const language = R.path(["node_locale"], pageContent)

  const microCopyTexts =
    language === "sv"
      ? useMicroCopySv
      : language === "en"
      ? useMicroCopyEn
      : useMicroCopyFi

  const labelHomePage = GetMicroCopy(microCopyTexts, "yleinenEtusivu")

  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const title = R.path(["title"], pageContent)
  const folder = R.path(["folder"], pageContent)
  const folderSlug = R.path(["folderSlug"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)

  const {
    shape_lightGreen,
    shape_darkGreen,
    shape_yellow,
    shape_pink,
    shape_lightBlue,
  } = data

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const shapeDarkGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_darkGreen
  )
  const shapeYellow = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_yellow
  )
  const shapePink = R.path(["childImageSharp", "gatsbyImageData"], shape_pink)
  const shapeLightBlue = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightBlue
  )

  let textContent = R.path(["contentText", "contentText"], pageContent) || ""
  const regexEmbedlyText = new RegExp("Embedded content: (.*)", "g")
  const regexYouTube =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?‌​=]*)?/g
  textContent = textContent
    .replace(regexEmbedlyText, "")
    .replace(regexYouTube, "https://youtu.be/$1?rel=0")

  const mobileSizeDetected = useMediaQuery("(max-width:700px)")
  let shapeColor = ""
  let shapeSize = ""
  let shapeMinHeight = ""
  let titleMarginBottom = ""

  shapeMinHeight = mobileSizeDetected ? "41px" : "190px"
  titleMarginBottom = mobileSizeDetected ? "-25px" : "-5px"

  const color = R.path(["color"], pageContent)
  if (color === "Vihreä") {
    shapeColor = shapeLightGreen
    shapeSize = mobileSizeDetected ? "60px 41px" : "190px 129px"
  }
  if (color === "Tummanvihreä") {
    shapeColor = shapeDarkGreen
    shapeSize = mobileSizeDetected ? "62px 41px" : "190px 129px"
  }
  if (color === "Keltainen") {
    shapeColor = shapeYellow
    shapeSize = mobileSizeDetected ? "62px 41px" : "193px 129px"
  }
  if (color === "Sininen") {
    shapeColor = shapeLightBlue
    shapeSize = mobileSizeDetected ? "62px 41px" : "191px 128px"
  }
  if (color === "Pinkki") {
    shapeColor = shapePink
    shapeSize = mobileSizeDetected ? "62px 41px" : "203px 135px"
  }

  return (
    <LayoutController language={language}>
      <Box>
        <Seo
          seoTitle={title}
          seoDesc={contentTextShort}
          url={typeof window !== "undefined" ? window.location.href : ""}
          image={mainImageSEO}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "35px",
            marginBottom: "100px",
            padding: "0 20px",
          }}
        >
          <Box style={{ maxWidth: "1200px", width: "100%" }}>
            <Box>
              <Grid container>
                <Grid item sm={12} lg={6} className={styles.titleContainer}>
                  <div
                    className={styles.bgimageContainer}
                    style={{ marginLeft: "-35px" }}
                  >
                    <BgImage
                      image={shapeColor}
                      style={{
                        minHeight: shapeMinHeight,
                        backgroundSize: shapeSize,
                        backgroundPositionX: "0px",
                      }}
                    >
                      <div
                        className={styles.header}
                        style={{ marginBottom: titleMarginBottom }}
                      >
                        <h1>{title}</h1>
                      </div>
                    </BgImage>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box className={styles.mainImageContainer}>
              <GatsbyImage image={mainImage} style={{ maxHeight: "656px" }} />
            </Box>

            {R.path(["subpages"], pageContent) && (
              <Grid container direction="row">
                <Grid item sm={12} md={4}>
                  <p className="categoryText">
                    <Link to="/">{labelHomePage}</Link> ·{" "}
                    <Link to={`/${folderSlug}`}>{folder}</Link>
                  </p>
                  <BasicPageSidebar
                    pageContent={pageContent}
                    language={language}
                  />
                </Grid>
                <Grid item sm={false} md={1}></Grid>
                <Grid item sm={12} md={7}>
                  <h1>{title}</h1>
                  <ReactMarkdown children={textContent} softBreak="br" />
                  <LiftContent pageContent={pageContent} />
                </Grid>
              </Grid>
            )}

            {!R.path(["subpages"], pageContent) && (
              <Grid container direction="row">
                <Grid item sm={12}>
                  <ReactMarkdown children={textContent} softBreak="br" />

                  <LiftContent pageContent={pageContent} />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </LayoutController>
  )
}

export default BasicPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query basicPageQuery($slug: String) {
    contentfulContent: contentfulPerussivu(slug: { eq: $slug }) {
      id
      slug
      title
      folder
      folderSlug
      node_locale
      color
      mainImage {
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
        file {
          url
        }
      }
      contentTextShort
      contentText {
        contentText
      }
      subpages {
        node_locale
        ... on Node {
          ... on ContentfulPerusalasivu {
            __typename
            slug
            title
          }
        }
      }
      liftContent {
        ... on Node {
          # Nostolista
          ... on ContentfulNostolista {
            __typename
            id
            color
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            title
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  kategoria
                  title
                  contentTextShort
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                  title
                  contentTextShort
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  name
                  contentTextShort
                  kategoria
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          # Varinosto
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          # Artikkelinostot
          ... on ContentfulArtikkelinostot {
            __typename
            title
            id
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            articleLiftups {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  createdAt(formatString: "MMMM Do YYYY", locale: "fi")
                  slug
                  title
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          # Sisaltoelementti
          ... on ContentfulSisaltoelementti {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            image {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            ctaButtonAction
          }
          # Videonosto
          ... on ContentfulVideonosto {
            __typename
            id
            title
            contentText {
              contentText
            }
            videoUrl
            thumbNail {
              id
              file {
                url
              }
              gatsbyImageData(quality: 60, layout: CONSTRAINED)
            }
            ctaButtonText
            buttonSlug
          }
          # Tekstilinkkilista
          ... on ContentfulTekstilinkkilista {
            __typename
            id
            title
            contentText {
              contentText
            }
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  title
                  slug
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                  title
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  name
                  slug
                }
              }
            }
          }
          # AnimoidutNostot
          ... on ContentfulAnimoidutNostot {
            __typename
            id
            title
            lifts {
              id
              title
              text {
                text
              }
              buttonText
              buttonLink
              buttonAction {
                ... on Node {
                  ... on ContentfulKategoriasivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulArtikkelisivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulKohdesivu {
                    __typename
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    shape_darkGreen: file(relativePath: { eq: "shape_darkGreen.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    shape_yellow: file(relativePath: { eq: "shape_yellow.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    shape_pink: file(relativePath: { eq: "shape_pink.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    shape_lightBlue: file(relativePath: { eq: "shape_lightBlue.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
